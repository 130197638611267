.app-mood-modal {
  .form-input {
    margin-bottom: 1rem;
  }

  .form-input.mood > div {
    > div {
      display: flex;
      justify-content: space-between;
    }
  }

  .form-input.text {
    textarea {
      width: 100%;
      min-height: 4rem;
    }
  }
}
