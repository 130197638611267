.app-header {
  h1 {
    display: flex;
    align-items: center;
    gap: 0.3rem;
    font-size: 1.2rem;
  }

  button {
    padding: 0 auto;
    margin-right: 0;
  }

  img {
    max-width: 1em;
    max-height: 1em;
  }
}
